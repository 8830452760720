import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { readContract, writeContract } from '@wagmi/core';

import wagmiConfig from 'wagmiConfig';
import { TOTAL_ROYALTY_PERCENTAGE, CHAIN_ID } from 'constants';
import contracts from 'constants/contracts';
import useWalletContext from './useWalletContext';

const useRoyalties = ({ address }) => {
  const { chainId, address: myAddress } = useWalletContext();

  const [currentEarnings, setCurrentEarnings] = useStateIfMounted([]);
  const [isLoading, setIsLoading] = useStateIfMounted(false);
  const [isSaving, setIsSaving] = useStateIfMounted(false);

  const { RoyaltyRegistryStorage, RoyaltyRegistry } = contracts[chainId];

  const fetchRoyalties = async () => {
    setIsLoading(true);
    try {
      const data = await readContract(wagmiConfig, {
        abi: RoyaltyRegistryStorage.ABI,
        address: RoyaltyRegistryStorage.address,
        functionName: 'getRoyaltiesByToken',
        args: [address],
        account: myAddress,
      });

      if (data.royalties) {
        const earnings = data.royalties.map((item) => ({
          earnerAddress: item.account,
          percentage: item.value / TOTAL_ROYALTY_PERCENTAGE.MULTIPLIER,
        }));

        setCurrentEarnings(earnings);
      }
    } catch (error) {
      console.error('Error fetching royalties:', error);
    }

    setIsLoading(false);
  };

  const save = async (earnings) => {
    setIsSaving(true);
    try {
      const royalties = earnings.map((item) => [
        item.earnerAddress,
        item.percentage * TOTAL_ROYALTY_PERCENTAGE.MULTIPLIER,
      ]);
      const hash = await writeContract(wagmiConfig, {
        abi: RoyaltyRegistry.ABI,
        address: RoyaltyRegistry.address,
        functionName: 'setRoyaltiesByToken',
        args: [address, [...royalties]],
      });

      if (hash) {
        return hash;
      }
    } catch (error) {
      throw error;
    }
    setIsSaving(false);
  };

  useEffect(() => {
    if (chainId && chainId === CHAIN_ID) {
      fetchRoyalties();
    }
  }, [chainId]);

  return { currentEarnings, setCurrentEarnings, fetchRoyalties, save, isLoading, isSaving };
};

export default useRoyalties;
