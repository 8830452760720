import { useStateIfMounted } from 'use-state-if-mounted';
import { addHours, setHours, setMinutes } from 'date-fns';
import { Typography, styled, useTheme } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { LISTING_DURATIONS } from 'constants';
import useCreateMakeOfferOrder from 'hooks/useCreateMakeOfferOrder';
import useNotification from 'hooks/useNotification';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';
import { useGetExchangeApprovedWNRG, useApproveExchangeWNRG } from 'utils/onchain/exchange';

import {
  CheckoutModalRow,
  ModalHeaderContainer,
  ModalIconButton,
} from '../../components/styledComponents';
import { GeneralFlexBox } from 'components/StyledComponents';
import WnrgDetails from './WnrgDetails';
import OfferProcess, { OFFER_PROCESS_STEP } from './OfferProcess';
import OfferExpiration from './OfferExpiration';
import { StandardButton } from 'components/Button';
import ConnectWallet from '../../components/ConnectWallet';

const MainContainer = styled(GeneralFlexBox)(({ display }) => ({
  display,
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 600px)': {
    height: '100%',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(() => ({
  flexGrow: 1,
  alignItems: 'flex-start',
  flexDirection: 'column',
}));

const CheckoutFooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
}));

const currentDate = new Date();

/********************  Main Component  ********************/
const MakeOffer = ({
  data,
  closeModal,
  ethBalance,
  wethBalance,
  lastPrice,
  setModal,
  visible = true,
}) => {
  const theme = useTheme();

  const { isConnected } = useWalletContext();
  const showNotification = useNotification();
  const { isAuthenticated } = useTokenContext();
  const approveExchangeWNRG = useApproveExchangeWNRG();
  const getExchangeApprovedWNRG = useGetExchangeApprovedWNRG();

  const [panel, setPanel] = useStateIfMounted();
  const [error, setError] = useStateIfMounted('');
  const [bidAmount, setBidAmount] = useStateIfMounted('');
  const [wnrgFailed, setWNRGFailed] = useStateIfMounted(false);
  const [confirmFailed, setConfirmFailed] = useStateIfMounted(false);
  const [wnrgProcessing, setWNRGProcessing] = useStateIfMounted(false);
  const [showOfferProcess, setShowOfferProcess] = useStateIfMounted(false);
  const [confirmProcessing, setConfirmProcessing] = useStateIfMounted(false);
  const [expiredDate, setExpiredDate] = useStateIfMounted(LISTING_DURATIONS[5].value); // 6 months
  const [exactTime, setExactTime] = useStateIfMounted({
    hours: currentDate.getHours(),
    minutes: currentDate.getMinutes(),
  });

  const { lastOwnerAddress: owner, contractAddress, tokenId } = data || {};
  const isConnectedAndAuthenticated = isConnected && isAuthenticated;
  const disabled = !Boolean(Number(bidAmount));

  const dateWithExactTime = new Date(
    setMinutes(
      setHours(addHours(new Date(), expiredDate || 24), exactTime.hours),
      exactTime.minutes,
    ),
  );

  const timeRange = {
    start: parseInt(new Date().getTime() / 1000),
    end: parseInt(dateWithExactTime.getTime() / 1000),
  };

  const createOBOrder = useCreateMakeOfferOrder({
    value: bidAmount,
    owner,
    contractAddress,
    tokenId,
    timeRange,
  });

  const handleMakeOffer = () => {
    if (Boolean(bidAmount) && Number(bidAmount) > Number(wethBalance)) {
      setError('Insufficient WNRG Balance');
    } else if (Number(bidAmount) < 0.0001) {
      setError('You must offer a minimum amount of 0.0001 WNRG');
    } else {
      setError('');
      allowWNRGIfNeeded();
    }
  };

  const allowWNRGIfNeeded = async () => {
    setShowOfferProcess(true);
    setPanel(OFFER_PROCESS_STEP.WETH_ENABLE);
    setWNRGFailed(false);
    setWNRGProcessing(true);
    const approvedWNRGAmount = await getExchangeApprovedWNRG();

    if (approvedWNRGAmount < bidAmount) {
      try {
        await approveExchangeWNRG();
        showNotification({ message: `WNRG enabled`, type: 'success' });
        handleConfirmOffer();
      } catch (err) {
        showNotification({ message: err.reason, type: 'error' });
        setWNRGFailed(true);
      } finally {
        setWNRGProcessing(false);
      }
    } else {
      setWNRGProcessing(true);
      handleConfirmOffer();
    }
  };

  const handleConfirmOffer = async () => {
    setPanel(OFFER_PROCESS_STEP.CONFIRM_OFFER);
    setConfirmFailed(false);
    setConfirmProcessing(true);

    try {
      await createOBOrder();
      setPanel(OFFER_PROCESS_STEP.POST_OFFER);
      showNotification({
        message: 'Successfully made offer.',
        type: 'success',
      });
    } catch (err) {
      showNotification({
        message: err.reason || err.message,
        type: 'error',
      });
      setConfirmFailed(true);
    } finally {
      setConfirmProcessing(false);
    }
  };

  return (
    <MainContainer direction="column" width="100%" display={visible ? 'flex' : 'none'}>
      <ModalHeaderContainer>
        <Typography fontSize={showOfferProcess ? 20 : 24} fontWeight={showOfferProcess ? 600 : 500}>
          {!isConnectedAndAuthenticated ? 'Connect wallet' : 'Make offer'}
        </Typography>
        <ModalIconButton onClick={closeModal}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalIconButton>
      </ModalHeaderContainer>
      {!isConnectedAndAuthenticated ? (
        <ConnectWallet />
      ) : showOfferProcess ? (
        <OfferProcess
          data={data}
          bidAmount={bidAmount}
          expiredDate={timeRange.end}
          closeModal={closeModal}
          panel={panel}
          wethFailed={wnrgFailed}
          onApproveWETH={allowWNRGIfNeeded}
          wethProcessing={wnrgProcessing}
          confirmFailed={confirmFailed}
          confirmProcessing={confirmProcessing}
          onConfirmOffer={handleConfirmOffer}
        />
      ) : (
        <>
          <MiddleRow sx={{ padding: theme.spacing(3.7, 3, 3, 3) }}>
            <WnrgDetails
              data={data}
              ethBalance={ethBalance}
              wethBalance={wethBalance}
              lastPrice={lastPrice}
              setModal={setModal}
              onMakeOffer={handleMakeOffer}
              bidAmount={bidAmount}
              onBidAmountChanged={setBidAmount}
              setError={setError}
              error={error}
            />
            <OfferExpiration
              expiredDate={expiredDate}
              setExpiredDate={setExpiredDate}
              dateWithExactTime={dateWithExactTime}
              setExactTime={setExactTime}
            />
          </MiddleRow>
          <CheckoutFooterRow>
            <StandardButton
              variant="contained"
              width={199}
              height={48}
              onClick={handleMakeOffer}
              disabled={disabled}
              disableRipple
            >
              Make Offer
            </StandardButton>
          </CheckoutFooterRow>
        </>
      )}
    </MainContainer>
  );
};

export default MakeOffer;
