import { ApiClient } from '../ApiClient';

export class StatsApi {
  /**
   * Constructs a new StatsApi.
   * @alias module:api/StatsApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.generalApiInstance;
  }

  /**
   * Get collection price statistic
   */
  async getCollectionPriceStats() {
    return await this.apiClient.callApi('/stats/collection', 'get', {}, {}, null);
  }

  /**
   * Get activity statistic
   * @param {string} listing Listing tx type - 'true' | 'false'
   * @param {string} mint Mint tx type - 'true' | 'false'
   * @param {Number} sale Sale tx type - 'true' | 'false'
   * @param {Number} offer Offer tx type - 'true' | 'false'
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getActivityStats({ listing, mint, sale, offer, bid, transfer, burn, skip, limit }) {
    return await this.apiClient.callApi(
      '/stats/activities',
      'get',
      {},
      { listing, mint, sale, offer, bid, transfer, burn, skip, limit },
      null,
    );
  }

  /**
   * Get Top Collections
   * @param {string} market Market which we query. 'gmi' | 'opensea' | 'all'
   * @param {string} period Period which calculate the statistic. '1H' | '3H' | '12H' | '1D' | '3D'
   * @param {string} direction Volume direction (asc, desc)
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getTopCollectionsStats({ market, period, direction, skip, limit }) {
    const body = { market, period, direction, skip, limit };
    return await this.apiClient.callApi('stats/topCollections', 'get', {}, body, null);
  }

  /**
   * Get user activity statistic
   * @param {string} address User address
   * @param {string} listing Listing tx type - 'true' | 'false'
   * @param {string} mint Mint tx type - 'true' | 'false'
   * @param {Number} sale Sale tx type - 'true' | 'false'
   * @param {Number} offer Offer tx type - 'true' | 'false'
   * @param {Number} skip Numer of items to skip for the result set
   * @param {Number} limit Number of items to retrieve
   */
  async getUserActivityStats({
    address,
    listing,
    mint,
    sale,
    offer,
    bid,
    transfer,
    burn,
    skip,
    limit,
  }) {
    return await this.apiClient.callApi(
      '/user/{address}/activities',
      'get',
      { address },
      { listing, mint, sale, offer, bid, transfer, burn, skip, limit },
      null,
    );
  }
}
