import { useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';

import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from './useWalletContext';
import { CheckoutModalContext } from 'pages/context';

export const useWalletConnect = () => {
  const { isConnected } = useWalletContext();
  const { openModal } = useContext(CheckoutModalContext);
  const { isAuthenticated } = useTokenContext();

  const [callback, setCallback] = useStateIfMounted(() => {});

  const verifyWalletOwnership = (passedCallback) => {
    if (!isAuthenticated) {
      openModal(
        {
          callback: passedCallback || callback,
        },
        'verify_wallet_ownership',
        'sign',
      );
    } else {
      passedCallback ? passedCallback() : callback ? callback() : null;
    }
  };

  const connectWallet = async (callback) => {
    try {
      setCallback(callback);
      if (!isConnected) {
        openModal({ metadata: {} }, 'select_wallet', 'sign');
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isConnected) {
      verifyWalletOwnership();
    }
  }, [isConnected]);

  return { connectWallet, verifyWalletOwnership };
};
