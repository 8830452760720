import { createContext, useContext, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import collectionService from 'services/collection';
import useWalletContext from 'hooks/useWalletContext';

export const UserCollectionsContext = createContext(null);
export const useUserCollectionsContext = () => useContext(UserCollectionsContext);

export const UserCollectionsProvider = ({ children }) => {
  const [collections, setCollections] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted();
  const { address } = useWalletContext();

  useEffect(() => {
    const getCollections = async () => {
      setLoading(true);
      if (address) {
        const response = await collectionService.getCollectionsByOwner(address);

        if (response?.collections) {
          response.collections.forEach((collection) => {
            collection.circulatingSupply = collection.totalMinted - collection.totalBurned;
          });
          setCollections(response.collections);
        }
      }
      setLoading(false);
    };

    getCollections();
  }, [address]);

  return (
    <UserCollectionsContext.Provider
      value={{
        loading,
        collections,
        setCollections,
      }}
    >
      {children}
    </UserCollectionsContext.Provider>
  );
};
