import { useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { styled, useTheme } from '@mui/material/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useMediaQuery } from '@mui/material';
import { Text } from 'components/Text';

const StyledToastContainer = styled(ToastContainer)(({ colorTheme, colorbottom }) => ({
  width: 440,

  '@media(max-width: 700px)': {
    width: 490,
  },

  '@media(max-width: 600px)': {
    width: 352,
    margin: 14,
  },

  '@media(max-width: 500px)': {
    width: 328,
    margin: 14,
  },

  '&.Toastify__toast-container--top-center': {
    left: 'calc(50% - 14px)',
    transform: 'translateX(-50%)',
  },

  '& .Toastify__toast': {
    background: colorTheme.palette.background.default,
    border: `1px solid ${colorTheme.palette.border.decorative}`,
    borderRadius: 8,
  },
  '& .Toastify__toast-body': {
    color: colorTheme.palette.text.main,
    fontSize: '16px',
    fontWeight: 600,

    '& svg': {
      color: colorbottom,
      margin: 'auto 0',
    },

    '& a': {
      textDecoration: 'none',
      color: colorTheme.palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
    },

    '& .close': {
      color: colorTheme.palette.text.secondary,
      margin: '0 auto',
      marginRight: 0,
      height: 20,
      width: 20,
    },
  },
  '& .Toastify__progress-bar': {
    background: colorbottom,
    height: 5,
  },
  '& .Toastify__close-button': {
    color: colorTheme.palette.text.main,
    opacity: 1,
  },
}));

const Notification = () => {
  const status = useSelector((state) => state.common.status);
  const message = useSelector((state) => state.common.message);
  const type = useSelector((state) => state.common.type);
  const link = useSelector((state) => state.common.link);
  const description = useSelector((state) => state.common.description);
  const notificationId = useSelector((state) => state.common.notificationId);
  const originalPosition = useSelector((state) => state.common.position) ?? 'bottom-right';
  const [position, setPosition] = useStateIfMounted(originalPosition);

  const below500 = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    if (below500) {
      setPosition('top-center');
    } else {
      setPosition(originalPosition);
    }
  }, [below500, originalPosition]);

  const theme = useTheme();

  const renderMsg = () => {
    return (
      <div style={{ marginLeft: 10 }}>
        {message}
        <br />
        {link && (
          <a target="_blank" rel="noopener noreferrer" href={link}>
            View on Block Explorer{' '}
          </a>
        )}
        {description && (
          <Text secondary="true" fs={14} align="left">
            {description}
          </Text>
        )}
      </div>
    );
  };

  const triggerToast = () => {
    const color =
      type === 'success' || type === 'successNoIcon'
        ? theme.palette.support.success
        : type === 'error'
        ? theme.palette.support.error
        : type === 'pending'
        ? theme.palette.support.warning2
        : null;

    switch (type) {
      case 'warning':
        return toast(
          <div style={{ display: 'flex' }}>
            <WarningAmberOutlinedIcon style={{ fill: color }} /> {renderMsg()}
            <CloseOutlinedIcon className="close" />
          </div>,
          { progressStyle: { background: color } },
        );
      case 'error':
        return toast(
          <div style={{ display: 'flex' }}>
            <ErrorOutlineIcon style={{ fill: color }} /> {renderMsg()}
            <CloseOutlinedIcon className="close" />
          </div>,
          { progressStyle: { background: color } },
        );
      case 'success':
        return toast(
          <div style={{ display: 'flex' }}>
            <CheckCircleOutlineIcon style={{ fill: color }} /> {renderMsg()}
            <CloseOutlinedIcon className="close" />
          </div>,
          { progressStyle: { background: color } },
        );
      case 'info':
        return toast(
          <div style={{ display: 'flex' }}>
            <InfoOutlinedIcon style={{ fill: color }} /> {renderMsg()}
            <CloseOutlinedIcon className="close" />
          </div>,
          { progressStyle: { background: color } },
        );
      case 'pending':
        return toast(
          <div style={{ display: 'flex' }}>
            <TimerOutlinedIcon style={{ fill: color }} /> {renderMsg()}
            <CloseOutlinedIcon className="close" />
          </div>,
          { progressStyle: { background: color } },
        );
      case 'successNoIcon':
        return toast(
          <div style={{ display: 'flex' }}>
            {renderMsg()}
            <CloseOutlinedIcon className="close" />
          </div>,
          { progressStyle: { background: color } },
        );
      default:
        return toast(renderMsg);
    }
  };

  useEffect(() => {
    if (status && message) {
      triggerToast();
    }
  }, [status, message, notificationId]);

  return (
    <StyledToastContainer
      colorTheme={theme}
      position={position}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      closeButton={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default Notification;
