import { createContext, useMemo, useReducer } from 'react';

import stats from 'services/stat';
import { ACTIVITY_TYPES } from 'constants';

const initialState = {
  total: null,
  items: [],
  perPage: 25,
  page: 1,
  loading: true,
  filters: [],
  infiniteScrollLoading: false,
};

const ACTIONS = {
  SET_LOADING: 'SET_LOADING',
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  SET_PAGE: 'SET_PAGE',
  ADD_ITEMS: 'ADD_ITEMS',
  SET_PER_PAGE: 'SET_PER_PAGE',
  SET_FILTERS: 'SET_FILTERS',
  SET_INFINITE_SCROLL_LOADING: 'SET_INFINITE_SCROLL_LOADING',
};

const ActivityReducer = (state, action = null) => {
  switch (action.type) {
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_ITEMS:
      return {
        ...state,
        loading: false,
        items: action.payload.data,
        total: action.payload.totalItems,
      };
    case ACTIONS.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTIONS.ADD_ITEMS:
      return {
        ...state,
        items: [...state.items, ...action.payload.data],
        total: action.payload.totalItems,
      };
    case ACTIONS.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };
    case ACTIONS.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case ACTIONS.SET_INFINITE_SCROLL_LOADING:
      return {
        ...state,
        infiniteScrollLoading: action.payload,
      };
  }

  throw Error('Unknown action: ' + action.type);
};

export const ActivityContext = createContext(null);

export const ActivityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ActivityReducer, initialState);

  const setLoading = (value) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: value });
  };

  const parsedFilters = useMemo(() => {
    if (Boolean(state.filters.length)) {
      return Object.fromEntries(
        Object.keys(ACTIVITY_TYPES).map((item) => [item, state.filters.includes(item)]),
      );
    }
    return {};
  }, [state.filters]);

  const fetchActivity = async () => {
    setLoading(true);
    const response = await stats.getActivities({
      skip: (state.page - 1) * state.perPage,
      limit: state.perPage,
      ...parsedFilters,
    });
    setItems(response);
  };

  const handleNextPage = async () => {
    setLoading(true);
    const response = await stats.getActivities({
      skip: state.page * state.perPage,
      limit: state.perPage,
      ...parsedFilters,
    });
    setItems(response);
    setPage(state.page + 1);
    setLoading(false);
  };

  const handlePrevPage = async () => {
    setLoading(true);
    const response = await stats.getActivities({
      skip: (state.page - 2) * state.perPage,
      limit: state.perPage,
      ...parsedFilters,
    });
    setItems(response);
    setPage(state.page - 1);
    setLoading(false);
  };

  const fetchUserActivity = async (address) => {
    setLoading(true);
    const response = await stats.getUserActivities({
      address,
      skip: (state.page - 1) * state.perPage,
      limit: state.perPage,
      ...parsedFilters,
    });
    setItems(response);
  };

  const fetchMoreUserActivity = async (address) => {
    const response = await stats.getUserActivities({
      address,
      skip: state.items.length,
      limit: state.perPage,
      ...parsedFilters,
    });
    dispatch({ type: ACTIONS.ADD_ITEMS, payload: response });
    setInfiniteScrollLoading(false);
  };

  const setItems = (items) => {
    dispatch({ type: ACTIONS.SET_ITEMS, payload: items });
  };

  const setPage = (page) => {
    dispatch({ type: ACTIONS.SET_PAGE, payload: page });
  };

  const setPerPage = (value) => {
    dispatch({ type: ACTIONS.SET_PER_PAGE, payload: value });
  };

  const setFilters = (value) => {
    setPage(1);
    dispatch({ type: ACTIONS.SET_FILTERS, payload: value ?? [] });
  };

  const setInfiniteScrollLoading = (value) => {
    dispatch({ type: ACTIONS.SET_INFINITE_SCROLL_LOADING, payload: value });
  };

  return (
    <ActivityContext.Provider
      value={{
        state,
        dispatch,
        setLoading,
        handleNextPage,
        handlePrevPage,
        setPerPage,
        setPage,
        setFilters,
        parsedFilters,
        fetchUserActivity,
        fetchActivity,
        setInfiniteScrollLoading,
        fetchMoreUserActivity,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
