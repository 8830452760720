import { ConnectButton } from 'components/Button';
import { MetamaskFaceIcon } from 'components/ImageComponent';
import { GeneralFlexBox } from 'components/StyledComponents';
import { Text } from 'components/Text';
import { Divider } from 'components/Modal';

/********************  Main Component  ********************/
const ConnectWallet = () => {
  return (
    <>
      <GeneralFlexBox direction="column" width="100%" padding="16px 14px">
        <MetamaskFaceIcon />
        <Text main="true" fs={32} fw={600} lh={40} mt={16} mb={16}>
          Connect Wallet
        </Text>
        <Text
          fs={14}
          fw={500}
          lh={20}
          sx={{
            color: (theme) =>
              theme.palette.mode === 'dark'
                ? theme.palette.text.placeholder
                : theme.palette.text.tertiary,
          }}
        >
          Please connect your wallet to continue.
        </Text>
      </GeneralFlexBox>
      <Divider />
      <GeneralFlexBox sx={{ py: 3 }}>
        <ConnectButton width={199}>Connect Wallet</ConnectButton>
      </GeneralFlexBox>
    </>
  );
};

export default ConnectWallet;
