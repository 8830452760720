import React, { createContext, useEffect, useState } from 'react';
import { signMessage } from '@wagmi/core';

import userService from 'services/user';

import wagmiConfig from 'wagmiConfig';
import { AUTH_MESSAGE_TO_SIGN, SIGNATURE_EXPIRY_TIME } from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';
import useNotification from 'hooks/useNotification';

export const TokenContext = createContext(null);

export const TokenProvider = ({ children }) => {
  const { disconnect, address } = useWalletContext();
  const showNotification = useNotification();

  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState();
  const [inUseAddress, setInUseAddress] = useState();

  const signOut = async (isTokenInvalid = false) => {
    setLoading(true);
    if (localStorage.token) {
      if (!isTokenInvalid) {
        await userService.logout();
      }

      localStorage.removeItem('token');
    }

    disconnect();
    setLoading(false);
  };

  useEffect(() => {
    let token;
    setInUseAddress(address);

    if (!inUseAddress || address == inUseAddress) {
      token = localStorage.token;
    }

    if (token && token !== '') {
      const parsed = JSON.parse(window.atob(token.split('.')[1]));
      const expiry = localStorage.token_expiry;

      // if JWT is expired
      if (
        !token ||
        expiry < new Date() ||
        !parsed ||
        (address && address !== parsed.address.toLowerCase())
      ) {
        localStorage.token = '';
        setAuthenticated(false);
      }

      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [address]);

  const signIn = async () => {
    setLoading(true);
    try {
      const nonceData = await userService.getNonce(address);

      if (nonceData?.message === 'Account locked!') {
        return showNotification({ type: 'error', message: nonceData?.message });
      }

      const signResult = await signMessage(wagmiConfig, {
        message: AUTH_MESSAGE_TO_SIGN(address, nonceData.data.nonce),
      });

      const authResponseData = await userService.authenticate(address, signResult);
      if (authResponseData) {
        const { token } = authResponseData.data.auth;
        localStorage.setItem('token', token);
        localStorage.setItem('token_expiry', Date.now() + SIGNATURE_EXPIRY_TIME);

        setAuthenticated(true);
        showNotification({ type: 'success', message: 'Wallet connected successfully' });
      }
    } catch (error) {
      showNotification({ type: 'error', message: 'Failed to connect wallet' });
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <TokenContext.Provider
      value={{
        isAuthenticated,
        loading,
        signIn,
        signOut,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
