export const ENERGI_TESTNET = {
  id: 49797,
  name: 'Energi',
  network: 'energi',
  nativeCurrency: {
    name: 'Energi',
    symbol: 'NRG',
    decimals: 18,
  },
  rpcUrls: {
    public: { http: ['https://nodeapi.test.energi.network'] },
    default: { http: ['https://nodeapi.test.energi.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'Energi explorer', url: 'https://explorer.test.energi.network' },
    default: { name: 'Energi explorer', url: 'https://explorer.test.energi.network' },
  },
  contracts: {
    multicall3: {
      address: '0x9510e36CAe130B28184B1aD3852FE5F67193c341',
      blockCreated: 2_441_944,
    },
  },
};

export const ENERGI_MAINNET = {
  id: 39797,
  name: 'Energi',
  network: 'energi',
  nativeCurrency: {
    name: 'Energi',
    symbol: 'NRG',
    decimals: 18,
  },
  rpcUrls: {
    public: { http: ['https://nodeapi.energi.network'] },
    default: { http: ['https://nodeapi.energi.network'] },
  },
  blockExplorers: {
    etherscan: { name: 'Energi explorer', url: 'https://explorer.energi.network' },
    default: { name: 'Energi explorer', url: 'https://explorer.energi.network' },
  },
  contracts: {},
};

export const NETWORK_RPC_URLS = {
  1: 'https://ethereum.energi.network',
  5: 'https://ethereum.test.energi.network',
  39797: 'https://nodeapi.energi.network',
  49797: 'https://nodeapi.test.energi.network',
};
