import { useContext } from 'react';

import { CheckoutModalContext } from 'pages/context';

import { StandardButton } from './Button';

export const ConnectButton = ({ width, height, disabled, onClick, children }) => {
  const { openModal } = useContext(CheckoutModalContext);

  const handleClick = async () => {
    openModal({ metadata: {} }, 'select_wallet', 'sign');
  };

  return (
    <>
      <StandardButton
        width={width ?? '100%'}
        height={height ?? 48}
        disabled={disabled}
        onClick={onClick ?? handleClick}
      >
        {children}
      </StandardButton>
    </>
  );
};
