import React from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { styled } from '@mui/material/styles';
import { Grow, Paper, MenuList, Popper } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MenuButton, MenuItem, MenuText } from 'components/StyledComponents';
import { MENU_CLOSING_TIME } from 'constants/index';
import { DiscordNewIcon } from 'components/ImageComponent';
import { openLink } from 'utils/openLink';
import { isExternalLink } from 'utils/helper';

/********************  Styled Components  ********************/
const SocialMenuItem = styled('div')(({ theme }) => ({
  height: 75,
  display: 'flex',
  backgroundColor: theme.palette.background.secondary,
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.border.separator}`,
  justifyContent: 'space-around',
  '& a': {
    color: theme.palette.text.secondary,
    transition: '0.1s',
    '&:hover': {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main,
    },
  },
}));

const SubMenuComponent = styled(Popper)(() => ({
  width: '220px',
  margin: '0px',
  zIndex: 1, // Only need 1 to be above the header
}));

const SubMenuList = styled(MenuList)(() => ({
  padding: '0px',
}));

const Link = styled('a')(({ theme }) => ({
  color: theme.palette.text.main,
  lineHeight: '24px',
  letterSpacing: '0.15px',
  fontWeight: 600,
  textDecoration: 'none',
}));

const CustomMenuButton = styled(MenuButton)(({ theme, active }) => ({
  border: active === 'true' ? `3px solid ${theme.palette.link.primary}` : 'none',
  padding: theme.spacing(0.5, 1),
  height: 'auto',
  color: active === 'true' ? theme.palette.link.primary : theme.palette.text.main,
}));

/********************  Main Component  ********************/
const SubMenu = ({ index, name, path, menus, active }) => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useStateIfMounted(null);
  const open = Boolean(anchorEl);

  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClick = (event) => {
    if (!path) {
      return;
    }
    history.push(path);
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const goPath = (path) => {
    setAnchorEl(null);
    path.startsWith('https') ? openLink(path) : history.push(path);
  };

  const leaveMenu = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, MENU_CLOSING_TIME);
  };

  return (
    <div onMouseLeave={leaveMenu}>
      <CustomMenuButton
        id={`submenu-button-${index}`}
        ref={anchorRef}
        active={active.toString()}
        aria-controls={open ? `sub-menu-${index}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseOver={(event) => setAnchorEl(event.currentTarget)}
      >
        {name}
      </CustomMenuButton>
      <SubMenuComponent
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper elevation={2}>
              <SubMenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                sx={{ padding: 0 }}
              >
                {menus.map((menu, index) =>
                  menu.text === 'Social' ? (
                    <SocialMenuItem key={index}>
                      {menu.paths.map((media) => (
                        <a
                          key={media.name}
                          rel="noopener noreferrer"
                          target="_blank"
                          href={media.link}
                        >
                          {media.name === 'Discord' ? <DiscordNewIcon /> : media.icon}
                        </a>
                      ))}
                    </SocialMenuItem>
                  ) : isExternalLink(menu.path) ? (
                    <MenuItem
                      key={index}
                      sx={{
                        '&:last-child': {
                          borderBottom: 'none',
                        },
                      }}
                      onClick={() => goPath(menu.path)}
                    >
                      <Link rel="noopener noreferrer" target="_blank" href={menu.path}>
                        <MenuText>{menu.text}</MenuText>
                      </Link>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={index}
                      sx={{
                        '&:last-child': {
                          borderBottom: 'none',
                        },
                      }}
                      onClick={() => goPath(menu.path)}
                    >
                      {menu?.icon}
                      <MenuText className={menu.text === 'Rewards' && 'rewards-menu'}>
                        {menu.text}
                      </MenuText>
                    </MenuItem>
                  ),
                )}
              </SubMenuList>
            </Paper>
          </Grow>
        )}
      </SubMenuComponent>
    </div>
  );
};

export default SubMenu;
