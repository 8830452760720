import React, { createContext } from 'react';
import {
  useAccount,
  useConnect,
  usePublicClient,
  useWalletClient,
  useDisconnect,
  useSwitchChain,
} from 'wagmi';
import { useStateIfMounted } from 'use-state-if-mounted';

import { CHAIN_ID } from 'constants/index';

export const WalletContext = createContext(null);

export const WalletProvider = ({ children }) => {
  const { address: rawAddress, isConnected, chainId: initialChainId } = useAccount();
  const { connect, connectors: rawConnectors, connectAsync } = useConnect();
  const { disconnect } = useDisconnect();
  const chainId = initialChainId || CHAIN_ID; // nrg fallback
  const { data: signer } = useWalletClient({ chainId });
  const provider = usePublicClient();
  const { switchChainAsync } = useSwitchChain();

  const [walletModalOpen, setWalletModalOpen] = useStateIfMounted(false);

  const address = rawAddress?.toLowerCase();
  const isInstalled = typeof window.ethereum !== 'undefined';
  const wrongNetwork = isConnected && chainId !== CHAIN_ID;
  const connectors = rawConnectors
    .slice()
    .sort((prev, next) => (prev.name === 'MetaMask' ? -1 : next.name === 'MetaMask' ? 1 : 0));

  return (
    <WalletContext.Provider
      value={{
        isInstalled,
        isConnected,
        connectors,
        connect,
        connectAsync,
        provider,
        signer,
        chainId,
        address,
        disconnect,
        switchToNetwork: async () => await switchChainAsync({ chainId: CHAIN_ID }),
        walletModalOpen,
        setWalletModalOpen,
        wrongNetwork,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
