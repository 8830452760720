import React, { Suspense, useEffect } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter as Router } from 'react-router-dom';

import { CheckoutModalContext } from './context';
import { FavoriteNftsProvider } from 'context';
import useTopValue from 'hooks/useTopValue';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';
import { lightTheme, darkTheme } from 'styles/theme';
import { useDarkMode } from 'utils/useDarkMode';

import { routes } from './routes';
import { Header, LoadingBar, Notification } from 'components/General';
import { CustomSwitch, CustomRoute } from 'components/RouteComponents';
import CheckoutModal from 'components/General/CheckoutModal';
import BookmarkWarning from 'components/General/Warnings/BookmarkWarning';
import WrongNetworkWarning from 'components/General/Warnings/WrongNetworkWarning';
import MetamaskNotInstalledModal from 'components/MetamaskNotInstalledModal';

function Root() {
  const [theme, toggleTheme, componentMounted] = useDarkMode();
  const themeMode = theme === 'dark' ? darkTheme : lightTheme;
  const { marginTop } = useTopValue();
  const { isAuthenticated, signOut } = useTokenContext();
  const { isConnected, isInstalled } = useWalletContext();

  const [checkoutModalOpen, setCheckoutModalOpen] = useStateIfMounted(false);
  const [modalData, setModalData] = useStateIfMounted({ metadata: {} });
  const [modalType, setModalType] = useStateIfMounted('place_bid');
  const [typeClicked, setTypeClicked] = useStateIfMounted('buy');

  useEffect(() => {
    // if isAuthenticated is undefined, it means it didn't fetch the user status yet
    if (isConnected && isAuthenticated != undefined && !isAuthenticated) {
      setModalType('verify_wallet_ownership');
      setCheckoutModalOpen(true);
      setTypeClicked('sign');
    }
  }, [isConnected, isAuthenticated]);

  useEffect(() => {
    if (!isInstalled) {
      signOut();
    }
  }, [isInstalled]);

  if (!componentMounted) {
    return <div />;
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={themeMode}>
          <Router>
            <FavoriteNftsProvider>
              <Notification />
              <MetamaskNotInstalledModal />
              <CheckoutModalContext.Provider
                value={{
                  open: checkoutModalOpen,
                  openModal: (data, modal, type) => {
                    setModalData(data);
                    setModalType(modal);
                    setCheckoutModalOpen(true);
                    setTypeClicked(type);
                  },
                  closeModal: () => {
                    setCheckoutModalOpen(false);
                    setModalType('');
                    setTypeClicked('');
                  },
                }}
              >
                <WrongNetworkWarning />
                <BookmarkWarning />
                <Header toggleTheme={toggleTheme} />
                <div className="root" style={{ marginTop: marginTop }}>
                  <Suspense fallback={<LoadingBar />}>
                    <CustomSwitch>
                      {routes.map((item, index) => (
                        <CustomRoute key={index} {...item} />
                      ))}
                    </CustomSwitch>
                  </Suspense>
                </div>
                <CheckoutModal
                  open={checkoutModalOpen}
                  closeModal={() => {
                    setCheckoutModalOpen(false);
                    setModalType('');
                    setTypeClicked('');
                  }}
                  data={modalData}
                  type={modalType}
                  path={typeClicked}
                />
              </CheckoutModalContext.Provider>
            </FavoriteNftsProvider>
          </Router>
          <CssBaseline />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
}

export default Root;
