import { ZERO_ADDRESS } from 'constants/index';

import { buildDataField, getStringAssetClassContract, tokenIdtoHex } from './common';

// construct takerOrder and makerOrder for BUY
export const constructBuyOrders = (order, account) => {
  const nftAsset = {
    assetType: {
      assetClass: getStringAssetClassContract(order.MakerAsset.Class),
      data: buildDataField(order.MakerAsset.Contract, tokenIdtoHex(order.NftToken)),
    },
    value: '1',
  };

  const valueAsset = {
    assetType: {
      assetClass: getStringAssetClassContract(order.TakerAsset.Class),
      data: '0x',
    },
    value: order.TakerAsset.Value,
  };

  const takerOrder = {
    maker: account, // buyer
    makeAsset: valueAsset,
    taker: ZERO_ADDRESS, // seller
    takeAsset: nftAsset,
    salt: 0,
    start: order.MatchAfterTimestamp,
    end: order.MatchBeforeTimestamp,
    dataType: getStringAssetClassContract(order.DataType),
    data: '0x',
    collectionBid: false,
  };

  const makerOrder = {
    maker: order.MakerAsset.Owner, // seller
    makeAsset: nftAsset,
    taker: ZERO_ADDRESS, // buyer
    takeAsset: valueAsset,
    salt: parseInt(order.Salt),
    start: order.MatchAfterTimestamp,
    end: order.MatchBeforeTimestamp,
    dataType: getStringAssetClassContract(order.DataType),
    data: '0x',
    collectionBid: false,
  };
  return { takerOrder, makerOrder };
};
