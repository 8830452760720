import { WagmiProvider } from 'wagmi';
import { ReactHooksWrapper, setHook } from 'react-hooks-outside';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import wagmiConfig from 'wagmiConfig';
import { APP_VERSION } from 'constants';
import {
  BalanceProvider,
  BookmarkWarningProvider,
  TokenProvider,
  WalletProvider,
  UserProvider,
  WindowProvider,
} from 'context';
import { PriceProvider } from 'context/PriceContext';
import { UserCollectionsProvider } from 'context/UserCollectionsContext';
import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';

import Root from './Root';

setHook('tokenContext', useTokenContext);
setHook('walletContext', useWalletContext);

const queryClient = new QueryClient();

console.log(
  `%cGMI ${APP_VERSION}`,
  'color:white; font-size: 30px; border: 1rem ridge #00AB58 ; border-radius: 7px; padding: 10px; margin: 20px; -webkit-text-stroke: 1px #00AB58;',
);

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <WalletProvider>
          <PriceProvider>
            <TokenProvider>
              <WindowProvider>
                <BalanceProvider>
                  <UserProvider>
                    <UserCollectionsProvider>
                      <BookmarkWarningProvider>
                        <Root />
                        <ReactHooksWrapper />
                      </BookmarkWarningProvider>
                    </UserCollectionsProvider>
                  </UserProvider>
                </BalanceProvider>
              </WindowProvider>
            </TokenProvider>
          </PriceProvider>
        </WalletProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
