import { CONTRACT_ASSET_CLASSES } from 'constants/index';

// build data hex field 30 bytes each
export const buildDataField = (contractAddress, tokenId) => {
  const contract =
    contractAddress.indexOf('0x') >= 0
      ? contractAddress.slice(2).padStart(64, '0')
      : contractAddress.padStart(64, '0');

  return `0x${contract}${tokenId.split('0x')[1]}`;
};

// random number for salt
export const randomSalt = () => {
  return Math.floor(Math.random() * 1000000);
};

// convert string to hex
export const tokenIdtoHex = (str) => {
  const bigNumber = BigInt(str);
  const hexString = bigNumber.toString(16).padStart(64, '0');

  return `0x${hexString}`;
};

export const hexToTokenId = (hex) => {
  const bigNumber = BigInt(hex);
  return bigNumber.toString();
};

// get String value of asset class
export const getStringAssetClassContract = (value) => {
  const key = Object.keys(CONTRACT_ASSET_CLASSES).find(
    (key) => CONTRACT_ASSET_CLASSES[key] === value,
  );
  return CONTRACT_ASSET_CLASSES[`${key}_STR`];
};
