import { goerli, mainnet } from 'viem/chains';
import { createConfig, http } from 'wagmi';
import { coinbaseWallet, injected } from 'wagmi/connectors';

import { ENERGI_MAINNET, ENERGI_TESTNET } from 'constants/chains';

const wagmiConfig = createConfig({
  chains: [mainnet, goerli, ENERGI_MAINNET, ENERGI_TESTNET],
  connectors: [
    injected(),
    coinbaseWallet({
      appName: 'GonnaMakeIt Marketplace',
    }),
  ],
  transports: {
    [mainnet.id]: http(),
    [goerli.id]: http(),
    [ENERGI_MAINNET.id]: http(),
    [ENERGI_TESTNET.id]: http(),
  },
});

export default wagmiConfig;
