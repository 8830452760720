import { Fragment } from 'react';
import { useStateIfMounted } from 'use-state-if-mounted';
import { Typography, IconButton, styled, useTheme } from '@mui/material';
import { InfoOutlined as InfoIcon, Edit as EditIcon } from '@mui/icons-material';

import useWalletContext from 'hooks/useWalletContext';

import { ToolTip } from 'components/StyledComponents';
import Blockie from 'components/Blockie';

/************************* Styled Components  **************************/
const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: theme.spacing(3),
}));

const HiddenFileInput = styled('input')(() => ({
  visibility: 'hidden',
  height: 0,
  width: 0,
}));

const RequiredText = styled('span')(({ theme }) => ({
  color: theme.palette.text.error,
}));

const Label = styled('label')(({ theme }) => ({
  position: 'relative',
  fontSize: 16,
  fontWeight: 600,
  '& svg': {
    color: theme.palette.background.secondary,
  },
}));

const StyledImage = styled('img')(({ theme, type }) => ({
  width: 150,
  height: type === 'avatar' ? 150 : 120,
  borderRadius: type === 'avatar' ? '50%' : 10,
  cursor: 'pointer',
  marginTop: theme.spacing(2),
  zIndex: 0,
  objectFit: 'cover',

  '& .Mui-hovered': {
    filter: 'brightness(.5)',
    border: '3px solid red',
  },

  [theme.breakpoints.down('md')]: {
    width: 197,
    height: 150,
  },
  [theme.breakpoints.down('sm')]: {
    width: 155,
    height: 118,
  },
}));

const HoverContainer = styled('div')(({ theme, round }) => ({
  position: 'absolute',
  top: -1,
  left: -1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 152,
  height: 152,
  borderRadius: round ? '50%' : 10,
  cursor: 'pointer',
  marginTop: theme.spacing(2),
  zIndex: 1,
  backgroundColor: '#000',
  opacity: 0.8,
}));

const HoverBannerContainer = styled('div')(({ theme, round }) => ({
  position: 'absolute',
  top: -1,
  left: -1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 152,
  height: 122,
  borderRadius: round ? '50%' : 10,
  cursor: 'pointer',
  marginTop: theme.spacing(2),
  zIndex: 1,
  backgroundColor: '#000',
  opacity: 0.8,
  [theme.breakpoints.down('md')]: {
    width: 197,
    height: 150,
  },
  [theme.breakpoints.down('sm')]: {
    width: 155,
    height: 118,
  },
}));

const ToolTipIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
  marginLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const inputData = {
  avatar: {
    name: 'avatar',
    title: 'Profile Image',
    tooltip1: 'Recommended 350×350 px',
    tooltip2: 'Max size: 100MB',
  },
  banner: {
    name: 'banner',
    title: 'Profile Banner',
    tooltip1: 'Recommended 1400×400 px',
    tooltip2: 'Max size: 100MB',
  },
};

/************************* Main Component  **************************/
const UploadInput = ({ type, image, onFileChange, required = false, children }) => {
  const theme = useTheme();

  const { address } = useWalletContext();

  const [isHover, setHover] = useStateIfMounted(false);

  return (
    <Container>
      <Typography fontSize={16} fontWeight={600} color={theme.palette.text.main}>
        {inputData[type].title}

        <ToolTip
          title={
            <Fragment>
              <Typography fontSize={14} fontWeight={500}>
                {inputData[type].tooltip1}
              </Typography>

              <Typography fontSize={14} fontWeight={500}>
                {inputData[type].tooltip2}
              </Typography>
            </Fragment>
          }
          placement="top"
        >
          <ToolTipIconButton sx={{ color: theme.palette.text.secondary }}>
            <InfoIcon
              sx={{
                fontSize: '20px',
                color: theme.palette.text.main,
              }}
            />
          </ToolTipIconButton>
        </ToolTip>
      </Typography>

      {required && <RequiredText>*</RequiredText>}

      {children && (
        <Typography fontSize={12} color={theme.palette.text.medium} padding="0.5rem 0">
          {children}
        </Typography>
      )}

      <Label
        htmlFor={`field-${type}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {image ? (
          <StyledImage src={image} type={type} />
        ) : (
          <div style={{ marginTop: 16, marginRight: -10 }}>
            <Blockie avatar={address} size={150} />
          </div>
        )}
        {isHover &&
          (type === 'banner' ? (
            <HoverBannerContainer round={false}>
              <EditIcon />
            </HoverBannerContainer>
          ) : (
            <HoverContainer round={true}>
              <EditIcon />
            </HoverContainer>
          ))}
      </Label>
      <HiddenFileInput
        name={type}
        id={`field-${type}`}
        type="file"
        accept=".jpeg,.jpg,.png,.gif,.svg,.webp"
        onChange={onFileChange}
      />
    </Container>
  );
};

export default UploadInput;
