import { Typography, IconButton, Button, CircularProgress, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';

import useTokenContext from 'hooks/useTokenContext';
import useWalletContext from 'hooks/useWalletContext';

import { ReactComponent as WalletVerifyIcon } from 'assets/images/wallet-verify.svg';
import { FlexBox, GeneralFlexBox } from 'components/StyledComponents';
import { CheckoutHeadingRow, CheckoutModalRow } from '../components/styledComponents';

const MainContainer = styled(GeneralFlexBox)(() => ({
  width: '100%',
  flexDirection: 'column',
  // Move footer row to bottom screen when in vertical small screen
  '@media(max-width: 500px)': {
    height: '100vh',
    justifyContent: 'space-between',
  },
}));

const MiddleRow = styled(CheckoutModalRow)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3, 6),
  '@media (max-width: 480px)': {
    padding: theme.spacing(3),
  },
}));

const FooterRow = styled(CheckoutModalRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.border.separator}`,
  '@media (max-width: 500px)': {
    alignItems: 'flex-start',
  },
}));

const ModalCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
  color: theme.palette.text.tertiary,
  padding: 2,
  borderRadius: 4,

  '&:hover': {
    background: theme.palette.background.apply.hovered,
  },

  '&:focus': {
    background: theme.palette.background.apply.pressed,
  },

  '@media (max-width: 500px)': {
    top: theme.spacing(1.8),
  },
}));

const SignRequestButton = styled(Button)(({ theme, loading }) => ({
  marginLeft: theme.spacing(3),
  boxShadow: 'none',
  fontWeight: 600,
  fontSize: 16,
  width: loading === 'true' ? 'auto' : 139,
  height: 48,
}));

/********************  Main Component  ********************/
const VerifyWalletOwnership = ({ closeModal, callback }) => {
  const theme = useTheme();

  const { disconnect } = useWalletContext();
  const { signIn, loading } = useTokenContext();

  const handleCancel = () => {
    disconnect();
    closeModal();
  };

  const signRequest = async () => {
    try {
      await signIn();
      closeModal();
      callback && callback();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MainContainer direction="column" width="100%">
      <CheckoutHeadingRow padding={theme.spacing(3, 3, 2, 3)}>
        <Typography fontSize={24} fontWeight={500} lineHeight="32px">
          Verify Ownership
        </Typography>
        <ModalCloseButton onClick={handleCancel}>
          <CloseIcon style={{ fill: theme.palette.icon.tertiary }} />
        </ModalCloseButton>
      </CheckoutHeadingRow>
      <MiddleRow>
        <WalletVerifyIcon style={{ width: 72, height: 72 }} />
        <Typography fontSize={16} fontWeight={400} marginTop={2} textAlign="center">
          To continue, confirm you are the owner of this wallet.
        </Typography>
      </MiddleRow>
      <FooterRow>
        <Box>
          <Button onClick={handleCancel} sx={{ fontWeight: 600, fontSize: 16 }}>
            Cancel
          </Button>
          <SignRequestButton
            variant="contained"
            onClick={signRequest}
            disabled={loading}
            loading={loading.toString()}
          >
            {loading ? (
              <FlexBox>
                Signing Request <CircularProgress color="inherit" sx={{ p: 1 }} />
              </FlexBox>
            ) : (
              'Sign Request'
            )}
          </SignRequestButton>
        </Box>
      </FooterRow>
    </MainContainer>
  );
};

export default VerifyWalletOwnership;
