import { getAddress, getContract } from 'viem';
import { ERC20ABI, PairABI, FarmingManagerABI, SushiswapRouterABI } from 'constants/abis';
import {
  DEAD_ADDRESS,
  FARMING_ADDRESS,
  FARMING_PROXY_ADDRESS,
  GMI_TOKEN_DETAILS,
  ZERO_ADDRESS,
  SUSHISWAP_ROUTER_ADDRESS,
} from 'constants/index';
import useWalletContext from 'hooks/useWalletContext';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export function useContract(contractAddress, ABI) {
  const { provider, signer } = useWalletContext();

  if (
    !isAddress(contractAddress) ||
    contractAddress === ZERO_ADDRESS ||
    contractAddress === DEAD_ADDRESS
  ) {
    console.warn(`Invalid 'contractAddress' parameter '${contractAddress}'.`);
    return null;
  }

  return getContract({
    address: contractAddress,
    abi: ABI,
    client: { wallet: signer, public: provider },
  });
}

export function useFarmingProxyContract(type) {
  const { chainId } = useWalletContext();
  return useContract(
    FARMING_PROXY_ADDRESS[chainId === 1 ? 'Mainnet' : 'Testnet'][type],
    FarmingManagerABI.abi,
  );
}

export function useFarmingContract() {
  const { chainId } = useWalletContext();
  return useContract(FARMING_ADDRESS[chainId === 1 ? 'Mainnet' : 'Testnet'], PairABI.abi);
}

export function useSushiswapRouterContract() {
  const { chainId } = useWalletContext();
  return useContract(
    SUSHISWAP_ROUTER_ADDRESS[chainId === 1 ? 'Mainnet' : 'Testnet'],
    SushiswapRouterABI.abi,
  );
}

export function useTokenContract() {
  const { chainId } = useWalletContext();
  return useContract(
    GMI_TOKEN_DETAILS[chainId === 1 ? 'Mainnet' : 'Testnet'].address,
    ERC20ABI.abi,
  );
}

export const toFixedDecimals = (value, decimals = 2) => {
  const countZeros =
    (value?.toFixed(20).toString().split('.')[1]?.match(/^0+/) || [''])[0]?.length ?? 0;

  if (countZeros === 20) {
    return value.toString() + '.00';
  } else {
    const digits = countZeros + decimals;
    let regex = value
      ? value
          .toFixed(20)
          .toString()
          .match(new RegExp(`^-?\\d+(?:\.\\d{0,${digits}})?`))[0]
      : 0;
    return regex;
  }
};

export function calculateGasMargin(value) {
  return (value * 10000n + 1000n) / 10000n;
}

export function calculateGas(value) {
  return value + 100_000n; // buffer gas allowance
}

export const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const shortenAddress = (address, slice = false) => {
  return (
    address &&
    (slice ? address.slice(2, 8).toUpperCase() : `${address.slice(0, 6)}...${address?.slice(-4)}`)
  );
};

export const shareOnTwitterURL = ({ text, via, url }) => {
  const encodedUrl = encodeURI(url);
  return `https://twitter.com/intent/tweet?text=${text}&url=${encodedUrl}&via=${via}&original_referer=${url}`;
};

export const areEqualObjects = (first, second, options = {}) => {
  const { caseSensitive } = options;
  return (
    first.length === second.length &&
    Object.keys(first).every((firstKey) =>
      second.find((item) =>
        Object.keys(first[firstKey]).every((key) => {
          if (
            typeof item[key] == 'string' &&
            typeof first[firstKey][key] === 'string' &&
            caseSensitive
          ) {
            return item[key].toLowerCase() == first[firstKey][key].toLowerCase();
          }
          return item[key] == first[firstKey][key];
        }),
      ),
    )
  );
};

export const decimalToNumber = (decimalString) => {
  return Number(decimalString)
    .toFixed(2)
    .replace(/[.,]00$/, '');
};

export const isZeroAddress = (address) => {
  return address === ZERO_ADDRESS;
};

export const isDeadAddress = (address) => {
  return address === DEAD_ADDRESS;
};
